import fetch from "node-fetch";

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	/**
	 * If session data exist in local storage, set value as session header.
	 */
	let session = null;
	if (typeof window !== "undefined")
		session =  localStorage?.getItem("woo-session");

	if (session) {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				"woocommerce-session": `Session ${session}`
			}
		}));
	}

	return forward(operation);

});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {

	return forward(operation).map(response => {
		//Si hay algun problema quitar esto
		if (!(typeof window !== "undefined")) {
			return response;
		}

		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const { response: { headers } } = context;
		const session = headers.get("woocommerce-session");

		if (session) {

			// Remove session data if session destroyed.
			if ("false" === session) {

				localStorage.removeItem("woo-session");

				// Update session new data if changed.
			} else if (localStorage.getItem("woo-session") !== session) {

				localStorage.setItem("woo-session", headers.get("woocommerce-session"));

			}
		} /*else {
			var cart = localStorage.getItem("classic-cart");
			if (cart) localStorage.setItem("classic-cart", null);
		}*/

		return response;

	});
});

// Apollo GraphQL client.
const client = new ApolloClient({
	link: middleware.concat(afterware.concat(createHttpLink({
		uri: `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/gql8989dfg44fg5d4f6eegd5fr65`,
		fetch: fetch,
		credentials: "include"
	}))),
	cache: new InMemoryCache(),
});

export default client;
