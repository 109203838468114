/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
export const PagoContext = React.createContext();

export const PagoProvider = ( props ) => {

    const [checkoutPay, setCheckoutPay] = useState(null);
  
    useEffect( () => {

		// @TODO Will add option to show the cart with localStorage later.
	/*	if ( typeof window !== "undefined" ) {

			let cartData = localStorage.getItem( "classic-cart" );
			cartData = null !== cartData ? JSON.parse( cartData ) : "";
			setCheckoutPay( cartData );

		}*/

    }, [] );
    
    return (
      <PagoContext.Provider value={ [ checkoutPay, setCheckoutPay ]}>
        {props.children}
      </PagoContext.Provider>
    );
  };
  export function usePagoContext() {
    return React.useContext(PagoContext);
}
