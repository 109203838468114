import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: "#F2F2F2",
        },
        error: {
            main: "#FF0000",
        },
    },
});

export default theme;