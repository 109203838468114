/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
export const AuthContext = React.createContext([
	{},
	() => {}
]);

export const AuthContextProvider = ( props ) => {

	const [user, setUser] = useState( null );

	useEffect( () => {
		
		//if (props.user)
		//	setUser(props.user);
		if ( typeof window !== "undefined" ) {

			let userData = localStorage.getItem( "classic-user" );
			userData = null !== userData ? JSON.parse( userData ) : "";
			setUser( userData );
		}

	}, [] );

	return (
		<AuthContext.Provider value={ [ user, setUser ] }>
			{ props.children }
		</AuthContext.Provider>
	);
};


export function useMyContext() {
	return useContext(AuthContext);
}