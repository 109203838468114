export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const pageview = () => {
  if (ThirdParty != "FALSE") {
    window.fbq("track", "PageView");
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  if (ThirdParty != "FALSE") {
    window.fbq("track", name, options);
  }
};
